import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {PICTURE_URL} from "../../../helpers/api.routes";
import Select from "react-select";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {IEnum} from "../../../store/slices/enum.slice";

export const CreateForm = ({
  setIsMenuOpened,
  state,
  setState,
  setImage,
}: any) => {
  const { guideLanguage } = useSelector((state: RootState) => state.enum) as IEnum;
  const { t } = useTranslation();
  const mappedLanguages = guideLanguage?.map((type: string) => ({
    label: t(`languages.${type?.toLowerCase()}`),
    value: type,
  }));
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  const selectHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, language: e.value }));
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="language" className="required-field">
              {t("global.language")}
            </label>
            <Select
                className="react-select-field"
                onChange={selectHandler}
                options={mappedLanguages}
                value={mappedLanguages?.find(
                    (data: any) => data.value === state?.language
                )}
            />
          </Col>
          <Col md={2} className=" mt-2">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input switch-custom"
                type="checkbox"
                name="active"
                checked={state?.active}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
        </Row>
        <div className="mt-3">
          <label htmlFor="imageFile"> {t("download.image")}</label>
          <input
            onChange={fileHandler}
            className="form-control"
            type="file"
            accept="image/*"
            id="imageFile"
          />
        </div>
        {state?.image && (
          <img
            className="mt-3 rounded show-img-form"
            alt="200x200"
            src={PICTURE_URL + state?.image.path}
            data-holder-rendered="true"
          />
        )}
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
                setImage(undefined);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
