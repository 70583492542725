import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { IPage } from "../interface";
import { AdminPageService } from "../service";

export const UpdatePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<IPage>({
    title: "",
    text: "",
  });

  const submitHandler = () => {
    AdminPageService.update(state, +id!)
      .then(() => {
        SuccessToast(t(`settings.successfully_saved`));
        navigate("/admin/page");
      })
      .catch(ErrorToast);
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm state={state} setState={setState} />
      </Form>
    </div>
  );
};
