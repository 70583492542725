import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {IPage} from "../page/interface";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {AdminGuidePageService} from "./service";
import {Pagination} from "../../shared/paginations/Paginations";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {IEnum} from "../../store/slices/enum.slice";
import Select from "react-select";

const AdminGuidePage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPrompt, setSelectedPrompt] = useState<any>()
    const { guideLanguage, guideMenuPosition } = useSelector((state: RootState) => state.enum) as IEnum;
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
        term: ""
    });
    const [list, setList] = useState<IPage[] | undefined>(undefined);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const mappedLanguages = guideLanguage?.map((type: string) => ({
        label: t(`languages.${type?.toLowerCase()}`),
        value: type,
    }));

    const mappedMenuPositions = guideMenuPosition?.map((type: string) => ({
        label: t(`enums.${type}`),
        value: type,
    }));

    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminGuidePageService.updatePosition({ ids: newListUpdated });
    };
    const editHandler = (id: number) => {
        navigate(`/admin/guide-page/update/${id}`);
    };

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminGuidePageService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`guide_page.successfully_deleted`));
                    setPagination((prev)=> ({...prev, updatedAt: new Date().getTime()}));
                }
            })
            .catch(ErrorToast);
    };

    useEffect(() => {
        AdminGuidePageService.getAll(pagination)
            .then((response) => {
                const data = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch(ErrorToast);
    }, [pagination]);

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                            <div className="search-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="searchMemberList"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setPagination((prev) => ({
                                            ...prev,
                                            term: e.target.value,
                                        }))
                                    }
                                    placeholder={t("global.search")}
                                />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                            <div className="d-flex align-items-center">
                                <h6 className="my-auto me-2 p-0 ">Language:</h6>
                                <Select
                                    className="react-select-field"
                                    onChange={(e) => {
                                        setPagination((prev) => ({...prev, language: e?.value}))
                                    }}
                                    placeholder="Search language"
                                    isClearable
                                    options={mappedLanguages}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <h6 className="my-auto me-2 p-0 ">Position:</h6>
                                <Select
                                    className="react-select-field"
                                    onChange={(e) => {
                                        setPagination((prev) => ({...prev, menuPosition: e?.value}))
                                    }}
                                    placeholder="Search menu positions"
                                    isClearable
                                    options={mappedMenuPositions}
                                />
                            </div>
                        </div>
                        <button
                            className="btn btn-primary"
                            onClick={() => navigate("/admin/guide-page/create")}
                        >
                            {t("global.add")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <DraggableBootstrapTable
                    preview={true}
                    list={list!}
                    setList={setList}
                    headers={["id", "title", "language", "menuPosition"]}
                    title="guide_page"
                    onEdit={editHandler}
                    selectedPrompt={selectedPrompt}
                    setSelectedPrompt={setSelectedPrompt}
                    handleShow={handleShow}
                    show={show}
                    setShow={setShow}
                    deleteHandler={deleteHandler}
                    updatePosition={updatePosition}
                />
            </div>
            {page && (
                <div className="d-flex justify-content-end">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default AdminGuidePage;