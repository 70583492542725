import { PAGE_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";
import {IPagination} from "../model/interface";
import {IPage} from "./interface";

export const AdminPageService = {
  getAll: (params: IPagination) => requests.get(`${PAGE_URL}`, params),
  create: (payload: IPage) => requests.post(`${PAGE_URL}`, payload),
  getSingle: (id: number) => requests.get(`${PAGE_URL}/${id}`),
  update: (payload: IPage, id: number) =>
    requests.post(`${PAGE_URL}/${id}`, payload),
  delete: (id: number) => requests.delete(`${PAGE_URL}/${id}`),
};
