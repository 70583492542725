import {requests} from "../../../../helpers/api.services";
import {GUIDE_EMPLOYEE_URL} from "../../../../helpers/api.routes";

export const AdminGuideEmployeeService = {
    getAll: (params: any) => requests.get(`${GUIDE_EMPLOYEE_URL}`, params),
    create: (params: any, language: string) => requests.post(`${GUIDE_EMPLOYEE_URL}?language=${language}`, params, true),
    getSingle: (id: number) => requests.get(`${GUIDE_EMPLOYEE_URL}/${id}`),
    update: (params: any, id: number, language: string) => requests.post(`${GUIDE_EMPLOYEE_URL}/${id}?language=${language}`, params, true),
    delete: (id: number) => requests.delete(`${GUIDE_EMPLOYEE_URL}/${id}`),
    updatePosition: (params: any) => requests.patch(`${GUIDE_EMPLOYEE_URL}`, params),
};