import React, {useEffect, useState} from 'react';
import DraggableBootstrapTable from "../../../../shared/components/draggableList/Draggable";
import {Pagination} from "../../../../shared/paginations/Paginations";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../../shared/toasters/toasters";
import {AdminGuideEmployeeService} from "./service";
import {CreateForm} from "./components/CreateForm";
import {List} from "./components/List";
import {Form} from "react-bootstrap";

interface IProps {
    companyId: number;
    rootState: { language: string };
}

const EmployeesTab = ({companyId, rootState}: IProps) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [image, setImage] = useState<File>();
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [state, setState] = useState<any>({
        title: "",
        isActive: false,
    });
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 50,
        term: "",
    });
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const [list, setList] = useState<any>(undefined);

    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminGuideEmployeeService.updatePosition({ids: newListUpdated});
    };

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminGuideEmployeeService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`employee.successfully_deleted`));
                    setTriggerUpdate((prev: boolean) => !prev);
                }
            })
            .catch((error) => ErrorToast(error));
    };

    const editHandler = (brandId: number) => {
        setIsMenuOpened(true);
        AdminGuideEmployeeService.getSingle(brandId)
            .then((response) => {
                const {data} = response;
                setState(data);
                window.scrollTo(0, 0);
            })
            .catch((error) => ErrorToast(error));
    };
    const submitHandler = () => {
        const formData = new FormData();
        const dataToSend = {...state, firm: companyId, isActive: state?.active};
        formData.append("body", JSON.stringify(dataToSend));
        image && formData.append("image", image);
        if (state.id) {
            AdminGuideEmployeeService.update(formData, state.id, rootState?.language)
                .then((response) => {
                    SuccessToast(t(`employee.successfully_updated`));
                    setIsMenuOpened(false);
                    setTriggerUpdate(!triggerUpdate);
                    setState({});
                    setImage(undefined);
                })
                .catch((error) => ErrorToast(error));
        } else {
            AdminGuideEmployeeService.create(formData, rootState?.language)
                .then((response) => {
                    SuccessToast(t(`employee.successfully_created`));
                    setIsMenuOpened(false);
                    setTriggerUpdate(!triggerUpdate);
                    setState({});
                    setImage(undefined);
                })
                .catch((error) => ErrorToast(error));
        }
    };
    useEffect(() => {
        !!companyId && AdminGuideEmployeeService.getAll({...pagination, firm: companyId})
            .then((response) => {
                const {data} = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [triggerUpdate, pagination, companyId]);
    return (
        <>
            {!isMenuOpened && (
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="search-box">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="searchMemberList"
                                        onChange={(e: any) =>
                                            setPagination((prev: any) => ({
                                                ...prev,
                                                page: 1,
                                                term: e.target.value,
                                            }))
                                        }
                                        placeholder={t("global.search")}
                                    />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => setIsMenuOpened(true)}
                                >
                                    {t("global.add")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isMenuOpened && (
                <div className="col-12">
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        submitHandler();
                    }}>
                        <CreateForm
                            setIsMenuOpened={setIsMenuOpened}
                            state={state}
                            setState={setState}
                            setImage={setImage}
                        />
                    </Form>
                </div>
            )}{" "}
            {page > 1 || pagination?.term !== "" ? (
                <>
                    <div className="col-12">
                        <List
                            // page={page}
                            // handlePages={handlePages}
                            // totalPages={totalPages}
                            list={list!}
                            setTriggerUpdate={setTriggerUpdate}
                            editHandler={editHandler}
                            // domain={domain}
                        />
                    </div>
                    {" "}
                </>
            ) : (
                <>
                    <div className="col-12">
                        <DraggableBootstrapTable
                            list={list}
                            preview={true}
                            setList={setList}
                            headers={["id", "firstName", "lastName", "email", "active"]}
                            title="employee"
                            onEdit={editHandler}
                            selectedPrompt={selectedPrompt}
                            setSelectedPrompt={setSelectedPrompt}
                            handleShow={handleShow}
                            show={show}
                            setShow={setShow}
                            deleteHandler={deleteHandler}
                            updatePosition={updatePosition}
                        />
                    </div>
                </>
            )}
            {page && (
                <div className="d-flex justify-content-end ">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default EmployeesTab;