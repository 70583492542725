import {requests} from "../../../../helpers/api.services";
import {GUIDE_FIRM_GALLERY_URL} from "../../../../helpers/api.routes";

export const AdminGuideFirmGalleryService = {
    getAll: (params: any) => requests.get(`${GUIDE_FIRM_GALLERY_URL}`, params),
    create: (params: any,language: string) => requests.post(`${GUIDE_FIRM_GALLERY_URL}?language=${language}`, params, true),
    getSingle: (id: number) => requests.get(`${GUIDE_FIRM_GALLERY_URL}/${id}`),
    update: (params: any, id: number, language: string) =>
        requests.put(`${GUIDE_FIRM_GALLERY_URL}/${id}?language=${language}`, params),
    delete: (id: number) => requests.delete(`${GUIDE_FIRM_GALLERY_URL}/${id}`),
    updatePosition: (params: any) =>
        requests.patch(`${GUIDE_FIRM_GALLERY_URL}`, params),
};