import React from "react";
import GuideFirmTabs from "./GuideFirmTabs";

export const UpdateGuideFirm = () => {
    return (
        <div className="col-12">
            <GuideFirmTabs isUpdate/>
        </div>
    );
};
