import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { nanoid } from "nanoid";
// import { Editor } from "@tinymce/tinymce-react";
import DatePicker from "react-datepicker";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminTestsService } from "../service";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AdminBrandService } from "../../brand/service";
import { AdminCategoryService } from "../../category/service";
import { AdminNewsThemeService } from "../../newsTheme/service";
import { useParams } from "react-router-dom";
import { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { PICTURE_URL } from "../../../helpers/api.routes";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import axios from "axios";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { AdminModelService } from "../../model/service";
import Compact from "@uiw/react-color-compact";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
export interface NewsItem {
  id?: number;
  title: string;
  intro: string;
  content: string;
  date: string;
  hideDate: boolean;
  isVideoHeadLine: boolean;
  isTape?: boolean;
  tapeText?: string;
  color?: string;
  isComment: boolean;
  isPaidContent: boolean;
  isTransporterGuide: boolean;
  isPremium: boolean;
  isArval: boolean;
  videoEmbed: string | null;
  script: string;
  mainImage?: {
    path?: string;
    isTape?: boolean;
    tapeText?: string;
    color?: string;
  };
  profilePicture: any;
  isActive: boolean;
  redirectToUrl: string;
  subtitle: string;
  author: any;
  brand?: any;
  model?: any;
  seoTitle: string;
  seoTags: string;
  seoDescription: string;
  link1: string;
  link2: string;
  link3: string;
  fileData: {
    id?: number | null;
    name?: string;
    path: string;
    legend: string;
    credit: string;
    index: null | number;
  }[];
  additionalFiles?: any[];
}

interface Props {
  disabledSubmit: boolean;
  state: NewsItem;
  setState: React.Dispatch<React.SetStateAction<NewsItem>>;
  setFiles: any;
  files: any;
  additionalFiles: any;
  setAdditionalFiles: any;
  submitHandler: any;
  setImage: any;
}

export const CreateForm = ({
  setImage,
  state,
  setState,
  files,
  setFiles,
  disabledSubmit,
  setAdditionalFiles,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [mainImage, setMainImage] = useState<any>("");
  const [copyWebsiteMulti, setCopyWebsiteMulti] = useState<string>("");
  const [brandsList, setBrandsList] = useState<any[]>();
  const [modelsList, setModelsList] = useState<any[]>();
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [categoryList, setCategoryList] = useState<any[]>();
  const [show, setShow] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>();
  const [editorData, setEditorData] = useState<any>();
  const [themesList, setThemesList] = useState<any[]>();
  const { website } = useSelector((state: RootState) => state.enum);
  const currentWebsite = localStorage.getItem("website");
  const firstLoad = useRef(true);
  const [selectEditor, setSelectEditor] = useState<boolean>(false);

  const mappedDataWebsite = website?.map((site: any) => ({
    label: site,
    value: site,
  }));

  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };

  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const deleteAdditional = (selected: any) => {
    AdminTestsService.deleteAdditional(selected.id)
      .then(() => {
        setState((prev: any) => ({
          ...prev,
          additionalFiles: prev.additionalFiles.filter(
            (file: any) => file.id !== selected.id
          ),
        }));
        SuccessToast(t("global.successfully_deleted"));
      })
      .catch((err) => {
        ErrorToast(err);
      });
  };
  const copyHandlerMulti = (selectedSingle: any) => {
    setCopyWebsiteMulti(
      selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      )
    );
  };
  const selectSingleHandler = (e: any, name: string) => {
    setState((prev: any) => ({
      ...prev,
      [name]: e.value,
    }));
  };
  const copyNews = (e: any) => {
    e.preventDefault();
    console.log(copyWebsiteMulti);
    AdminTestsService.copy({ websites: copyWebsiteMulti }, state?.id as number)
      .then((response) => {
        if (response?.status === "success") {
          SuccessToast(t(`Success copy news`));
        }
      })
      .catch((error) => ErrorToast(error));
  };
  const addFormFields = () => {
    setState((prev: any) => ({
      ...prev,
      fileData: [
        ...prev.fileData,
        {
          id: null,
          path: "",
          legend: "",
          credit: "",
          index: null,
        },
      ],
    }));
    const newFormData = [...state.fileData];
    const id = nanoid();
    newFormData.push({
      id: null,
      path: "",
      legend: "",
      credit: "",
      index: null,
    });
    setState((prev) => ({ ...prev, fileData: newFormData }));
  };

  const removeFormFields = (e: any, index: any, element: any) => {
    console.log(element);

    if (element?.id === null) {
      const newFilesArr = files.filter(
        (file: any) => file?.name !== element?.name
      );
      setFiles(newFilesArr);
      setState((prev) => ({
        ...prev,
        fileData: prev.fileData.filter((input, idx) => idx !== index),
      }));
    } else {
      AdminTestsService.deleteAdditional(element?.id)
        .then(() => {
          const newFilesArr = files.filter(
            (file: any) => file?.name !== element?.name
          );
          console.log(newFilesArr);
          setState((prev) => ({
            ...prev,
            fileData: prev.fileData.filter((input, idx) => idx !== index),
          }));
          setFiles(newFilesArr);
          SuccessToast(t("global.successfully_deleted"));
        })
        .catch((err) => {
          ErrorToast(err);
        });
    }
  };
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(date);
    setState((prev: any) => ({ ...prev, date: value }));
  }
  const cropperRef = useRef<ReactCropperElement>(null);

  // const onCrop = () => {
  //   const cropper = cropperRef.current?.cropper;

  //   setState((prev: any) => ({
  //     ...prev,
  //     mainImage: {
  //       ...prev.mainImage,
  //       base64: cropper!.getCroppedCanvas()?.toDataURL(),
  //     },
  //   }));
  // };
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper) return;
    cropper.getCroppedCanvas({ width: 800, height: 550 }).toBlob(
      (blob) => {
        if (!blob) return;

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          setState((prev: any) => ({
            ...prev,
            mainImage: {
              ...prev.mainImage,
              base64: reader.result,
            },
          }));
        };
      },
      "image/jpeg",
      0.9
    );
  };
  const handleMainImage = (e: any) => {
    setImage(e.target.files[0]);
    setMainImage(URL.createObjectURL(e.target.files[0]!));
  };

  const handleImageInput = (i: number, e: any, element: any) => {
    if (document.location.pathname.includes("create")) {
      if (e.target.files.length) {
        let newFormValuesImg = [
          ...state.fileData.map((file, index) =>
            index === i ? { ...file, index: files.length } : file
          ),
        ];
        (newFormValuesImg as any)[i][e.target.name] = URL.createObjectURL(
          e.target.files[0]
        );
        (newFormValuesImg as any)[i]["name"] = e.target.files[0]?.name;
        let arrayFiles = files;
        let newItem = e.target.files[0];
        let position = i;
        arrayFiles[position] = newItem;
        setFiles(arrayFiles);
        setState((prev) => ({ ...prev, fileData: newFormValuesImg }));
      }
    } else {
      if (e.target.files.length) {
        let newFormValuesImg = [
          ...state.fileData.map((file, index) =>
            index === i ? { ...file, index: files.length } : file
          ),
        ];
        (newFormValuesImg as any)[i][e.target.name] = URL.createObjectURL(
          e.target.files[0]
        );
        (newFormValuesImg as any)[i]["name"] = e.target.files[0]?.name;

        setFiles((prev: any) => [...prev, e.target.files[0]]);
        setState((prev) => ({ ...prev, fileData: newFormValuesImg }));
      }
    }
  };

  useEffect(() => {
    AdminBrandService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setBrandsList(
          data?.items.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
    AdminModelService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setModelsList(
          data?.items.map((item: any) => ({
            label: item.title,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
    AdminCategoryService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setCategoryList(
          data?.items.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
    AdminTestsService.getAllAuthors()
      .then((response) => {
        const { data } = response;

        setUserList(
          data?.map((item: any) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
    AdminNewsThemeService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setThemesList(
          data?.items.map((item: any) => ({
            label: item.title,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const handleText = (content: string) => {
    setState((prev: any) => ({ ...prev, content }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminTestsService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          InitEditor(data?.content, handleText);
          setState({
            ...data,
            brand: data?.brand?.id,
            author: data?.author?.id,
            model: data?.model?.id,
            isTape: data?.mainImage?.isTape,
            color: data?.mainImage?.color,
            tapeText: data?.mainImage?.tapeText,
            fileData:
              data?.fileData?.length === 0
                ? [
                    {
                      id: null,
                      name: "",
                      path: "",
                      legend: "",
                      credit: "",
                      index: null,
                    },
                  ]
                : data?.fileData,
          });
          data?.mainImage?.path == null
            ? console.log("mainImage err")
            : axios
                .get(
                  PICTURE_URL + data?.mainImage?.path,
                  { responseType: "arraybuffer" } // Set responseType to 'arraybuffer'
                )
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                  });
                  const url = URL.createObjectURL(blob);
                  setMainImage(url);
                });

          window.scrollTo(0, 0);
        })
        .catch((error) => {
          ErrorToast(error);
        });
    } else {
      InitEditor(undefined, handleText);
    }
  }, [id]);
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }

  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);

  return (
    <>
      <Row>
        <Col md={8}>
          <div className="card p-4">
            <div className="row my-2">
              <Col md={12}>
                <label htmlFor="title" className="required-field-news">
                  {t("global.title")}
                </label>
                <input
                  style={{ fontSize: "18px" }}
                  type="text"
                  name="title"
                  className="form-control"
                  required
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.title}
                />
              </Col>
            </div>
            <Row>
              <Col md={12} className="my-2">
                <div>
                  <label
                    htmlFor={`index-imageLegend-intro`}
                    className="form-label"
                  >
                    {t("global.intro")}
                  </label>
                  <textarea
                    className="form-control"
                    id={`index-imageLegend-intro`}
                    rows={3}
                    name="intro"
                    style={{ fontSize: "18px" }}
                    value={state?.intro}
                    onChange={(e) => changeHandler(e as any)}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <div>
              <label className="d-block text-start" htmlFor="content">
                {t("global.content")}
              </label>
            </div>
            <textarea className="editor" onChange={console.log}></textarea>
            {/* <N1ED
              id={`content`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={
                editorData ? editorData : state ? state?.content : ""
              }
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.content ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "content");
              }}
            /> */}
            <Row>
              <Col md={12} className="my-2">
                <div>
                  <label
                    htmlFor={`index-imageLegend-script`}
                    className="form-label"
                  >
                    {t("global.script")}
                  </label>
                  <textarea
                    className="form-control"
                    id={`index-imageLegend-script`}
                    rows={3}
                    name="script"
                    value={state?.script}
                    onChange={(e) => changeHandler(e as any)}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <div className="">
              <div>
                <div className="form-inline">
                  <Row>
                    <label
                      htmlFor=""
                      className={state?.isTape ? "required-field-news" : ""}
                    >
                      {" "}
                      {t("news.main_image")}
                    </label>
                    <Col md={12}>
                      <div className=" mt-2 mx-auto ">
                        <div className="card mb-0 p-3">
                          <div className="">
                            <div className="">
                              <div className="text-center">
                                <div className="profile-user position-relative d-inline-block mx-auto">
                                  {mainImage || state?.mainImage?.path ? (
                                    <CustomImageHandler
                                      path={
                                        mainImage
                                          ? mainImage
                                          : state?.mainImage?.path
                                      }
                                      classes="avatar-xl img-thumbnail user-profile-image"
                                      alt="user-profile-image"
                                    />
                                  ) : (
                                    <img
                                      src="/assets/images/img-placeholder.png"
                                      className="avatar-xl img-thumbnail user-profile-image"
                                      alt="user-profile-image"
                                    />
                                  )}
                                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                    <input
                                      name="path"
                                      type="file"
                                      accept="image/*"
                                      id="main"
                                      // ref={imageRef}
                                      className="profile-img-file-input "
                                      onChange={(e) => handleMainImage(e)}
                                    />
                                    <label
                                      htmlFor="main"
                                      className="profile-photo-edit avatar-xs"
                                    >
                                      <span className="avatar-title rounded-circle bg-light text-body">
                                        <i className="ri-camera-fill" />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div>
              <label>{t("global.otherImages")}</label>
              {state?.fileData?.map((element: any, index: any) => {
                return (
                  <div className="form-inline" key={element?.id}>
                    <Row>
                      <Col md={12}>
                        <div className=" mt-2 mx-auto ">
                          <div className="card mb-0">
                            <div className="card-body">
                              <div className="">
                                <div className="text-center">
                                  <div className="profile-user position-relative d-inline-block mx-auto">
                                    {(element as any)?.path ? (
                                      <CustomImageHandler
                                        path={
                                          element?.path
                                            ? (element as any)?.path
                                            : `${state?.profilePicture?.path}`
                                        }
                                        classes="avatar-xl img-thumbnail user-profile-image"
                                        alt="user-profile-image"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/images/img-placeholder.png"
                                        className="avatar-xl img-thumbnail user-profile-image"
                                        alt="user-profile-image"
                                      />
                                    )}
                                    <div
                                      key={index}
                                      className="avatar-xs p-0 rounded-circle profile-photo-edit"
                                    >
                                      <input
                                        name="path"
                                        id={index as any}
                                        type="file"
                                        accept="image/*"
                                        // ref={imageRef}
                                        className="profile-img-file-input "
                                        onChange={(e) =>
                                          handleImageInput(index, e, element)
                                        }
                                      />
                                      <label
                                        // htmlFor="profile-img-file-input"\
                                        htmlFor={index as any}
                                        className="profile-photo-edit avatar-xs"
                                      >
                                        <span className="avatar-title rounded-circle bg-light text-body">
                                          <i className="ri-camera-fill" />
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {index ? (
                      <>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) => removeFormFields(e, index, element)}
                          >
                            {t("global.remove")}
                          </button>
                          {index == state.fileData.length - 1 && (
                            <button
                              className="btn btn-primary ms-2"
                              type="submit"
                              onClick={() => {
                                addFormFields();
                              }}
                            >
                              {t("global.add_another")}
                            </button>
                          )}
                        </div>
                      </>
                    ) : index === 0 && state.fileData.length === 1 ? (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-primary ms-2"
                          type="submit"
                          onClick={() => {
                            addFormFields();
                          }}
                        >
                          {t("global.add_another")}
                        </button>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-end align-itmes-center my-2">
              <Button
                className="btn btn-success"
                type="submit"
                disabled={disabledSubmit}
              >
                {state?.id ? "Edit News" : "Create News"}
              </Button>
            </div>
          </div>
        </Col>
        <Col>
          <div className="card p-4">
            <div className="row my-2">
              <Col md={8}>
                <label htmlFor="date" className="required-field-news">
                  {t("global.date")}
                </label>
                <div className="datepicker-container">
                  <DatePicker
                    className="date-picker custom_zindex"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    value={state?.date}
                    onChange={(date) => handleDate(date as Date)}
                    isClearable
                    placeholderText="Choose date..."
                  />
                </div>
              </Col>
              <Col md={4} className=" mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.hide_date")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="hideDate"
                    checked={state?.hideDate}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
            </div>
            <div className="row mb-2">
              <Col md={12} className="">
                <label htmlFor="author" className="required-field">
                  {t("global.author")}
                </label>
                <Select
                  className="react-select-field"
                  onChange={(e) => selectSingleHandler(e, "author")}
                  options={userList}
                  value={userList?.find(
                    (author: any) => author.value === state?.author
                  )}
                />
              </Col>
              <Col md={12} className="my-2">
                <label htmlFor="brand" className="required-field">
                  {t("global.brand")}
                </label>
                <Select
                  required
                  className="react-select-field"
                  onChange={(e) => selectSingleHandler(e, "brand")}
                  options={brandsList}
                  value={brandsList?.find(
                    (brand: any) => brand.value === state?.brand
                  )}
                />
              </Col>
              <Col md={12} className="mb-2">
                <label htmlFor="model" className="required-field">
                  {t("global.model")}
                </label>
                <Select
                  className="react-select-field"
                  onChange={(e) => selectSingleHandler(e, "model")}
                  options={modelsList}
                  value={modelsList?.find(
                    (model: any) => model.value === state?.model
                  )}
                />
              </Col>
            </div>
            <div className="row my-2">
              <Col md={12} className="mb-2">
                <label htmlFor="subtitle" className="required-field">
                  {t("global.link1")}
                </label>
                <input
                  type="text"
                  name="link1"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.link1}
                />
              </Col>

              <Col md={12} className="mb-2">
                <label htmlFor="subtitle" className="required-field">
                  {t("global.link2")}
                </label>
                <input
                  type="text"
                  name="link2"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.link2}
                />
              </Col>
              <Col md={12} className="mb-2">
                <label htmlFor="subtitle" className="required-field">
                  {t("global.link3")}
                </label>
                <input
                  type="text"
                  name="link3"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.link3}
                />
              </Col>
              <Col md={6} className=" mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.active")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isActive"
                    checked={state?.isActive}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isVideoHeadLine")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isVideoHeadLine"
                    checked={state?.isVideoHeadLine}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isComment")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isComment"
                    checked={state?.isComment}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isPaidContent")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isPaidContent"
                    checked={state?.isPaidContent}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isTransporterGuide")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isTransporterGuide"
                    checked={state?.isTransporterGuide}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isPremium")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isPremium"
                    checked={state?.isPremium}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isAraval")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isAraval"
                    checked={state?.isArval}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
            </div>
          </div>
          <div className="card p-4">
            <h5>{t("global.add_tape")}</h5>
            <div className="row d-flex justify-content-between">
              <Col md={12} className="mb-2">
                <label htmlFor="tapeText">{t("global.tape_label")}</label>
                <input
                  type="text"
                  name="tapeText"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.tapeText}
                />
              </Col>
              <Col md={8} className="mb-3">
                <label htmlFor="color">{t("global.tape_color")}</label>
                <Compact
                  style={{ marginLeft: 20 }}
                  color={state?.color}
                  onChange={(color) => {
                    setState((prev: any) => ({ ...prev, color: color.hex }));
                  }}
                />
              </Col>
              <Col md={4} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isTape")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isTape"
                    checked={state?.isTape}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <p className={"text-muted"}>
                <span className={"text-danger"}>*</span>
                {t("global.tape_disclaimer")}
              </p>
            </div>
          </div>
          <div className="card p-4">
            <h5>{t("global.copyTo")}</h5>
            <div className="row d-flex align-items-end justify-content-between">
              <Col md={12}>
                <div className="mb-2">
                  <label htmlFor="website" className="required-field">
                    {t("table_headers.website")}
                  </label>
                  <Select
                    id="website"
                    className="react-select-field"
                    onChange={copyHandlerMulti}
                    isMulti={true}
                    options={(mappedDataWebsite as any)
                      ?.filter(
                        (website: any) => website.value !== currentWebsite
                      )
                      ?.map((site: any) => ({
                        value: site?.value,
                        label: t(`enums.${site?.label}`),
                      }))}
                  />
                </div>
              </Col>

              <Col md={3}>
                <div className="" style={{ marginBottom: 21 }}>
                  <label className="d-block text-start" htmlFor="ai">
                    {t("global.ai")}
                  </label>
                  <div className="form-check form-switch">
                    <input
                      id="ai"
                      className="form-check-input switch-custom"
                      type="checkbox"
                      name="ai"
                      role="switch"
                    />
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-2 ms-auto text-end">
                  <button
                    className="btn btn-info ms-auto"
                    onClick={(e) => copyNews(e)}
                  >
                    {t("news.copy")}
                  </button>
                </div>
              </Col>
            </div>
          </div>
          <div className="card p-4">
            <h5> {t("global.seo")}</h5>
            <Col md={12} className="my-2">
              <label htmlFor="seoTitle" className="required-field">
                {t("global.title")}
              </label>
              <input
                id="seoTitle"
                type="text"
                name="seoTitle"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.seoTitle}
              />
            </Col>
            <Col md={12} className="my-2">
              <label htmlFor="seoTags" className="required-field">
                {t("global.tags")}
              </label>
              <input
                id="seoTags"
                type="text"
                name="seoTags"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.seoTags}
              />
            </Col>
            <Col md={12} className="my-2">
              <label htmlFor="seoDescription" className="required-field">
                {t("global.description")}
              </label>
              <textarea
                id="seoDescription"
                name="seoDescription"
                rows={3}
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.seoDescription}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={selectedPrompt?.name}
        deleteHandler={deleteAdditional}
        product={selectedPrompt}
        selectedProduct={selectedPrompt}
      />
    </>
  );
};
export function removeElementsByClass(className: string) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements && elements[0]?.parentNode?.removeChild(elements[0]);
  }
}
export function InitEditor(value: string = "", cb: (value: string) => void) {
  (window as any).tinymce.init({
    selector: ".editor",
    apiKey: "a5kCyOAV",
    width: "100%",
    height: 500,
    menubar: "file edit view insert format tools table tc help",
    plugins:
      "image link file-manager print code preview importcss searchreplace autolink autosave save directionality  visualblocks visualchars fullscreen image link media  template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists  wordcount imagetools textpattern noneditable help   charmap  quickbars emoticons  ",
    statusbar: true,
    toolbar:
      "Upload undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | forecolor backcolor  removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media  template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment code",
    extended_valid_elements: "i[class]",
    init_instance_callback: (editor: any) => {
      editor.on("input", (e: any) => {
        cb(e.target.innerHTML);
      });
      editor.on("focus", (e: any) => {
        removeElementsByClass(
          "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
        );
      });
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            cb(editor.getContent());
          }
        }
      });
      const editorElement = editor.getContainer();
      observer.observe(editorElement, { childList: true, subtree: true });
    },
    setup: (editor: any) => {
      editor.on("init", () => {
        editor.setContent(value);
      });
    },
  });
}
