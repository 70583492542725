import {CreateForm} from "./CreateForm";
import React, {useState} from "react";
import {Form} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {IPage} from "../interface";
import {AdminPageService} from "../service";

export const CreatePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<IPage>({
    title: "",
    text: "",
  });

  const submitHandler = () => {
    AdminPageService.create(state)
      .then(() => {
        SuccessToast(t(`settings.successfully_saved`));
        navigate("/admin/page");
      })
      .catch(ErrorToast);
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm state={state} setState={setState} />
      </Form>
    </div>
  );
};
