import { createSlice } from "@reduxjs/toolkit";

export interface IEnum {
  subscriberCategory: string[];
  menuPosition: string[];
  guideMenuPosition: string[];
  website: string[];
  userRoles: string[];
  guideLanguage?: string[];
  siteSettings?: string[];
  userStatus?: string[];
  greeting: string[];
  genders: string[];
  firmType: string[];
  language: string[];
  country: string[];
  magazinesOrderByColumn?: string[];
}

const initialState: IEnum = {
  subscriberCategory: [],
  firmType: [],
  menuPosition: [],
  guideMenuPosition: [],
  website: [],
  userRoles: [],
  guideLanguage: [],
  greeting: [],
  genders: [],
  language: [],
  country: [],
  magazinesOrderByColumn: []
};

export const enumSlice = createSlice({
  name: "enum",
  initialState,
  reducers: {
    setEnums: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});
