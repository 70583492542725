import {requests} from "../../helpers/api.services";
import {GUIDE_PAGE_URL} from "../../helpers/api.routes";
import {IPagination} from "../model/interface";
import {IGuidePage} from "./interface";

export const AdminGuidePageService = {
    getAll: (params: IPagination) => requests.get(`${GUIDE_PAGE_URL}`, params),
    create: (payload: IGuidePage) => requests.post(`${GUIDE_PAGE_URL}`, payload),
    getSingle: (id: number) => requests.get(`${GUIDE_PAGE_URL}/${id}`),
    update: (payload: IGuidePage, id: number) => requests.post(`${GUIDE_PAGE_URL}/${id}`, payload),
    updatePosition: (params: any) => requests.patch(`${GUIDE_PAGE_URL}`, params),
    delete: (id: number) => requests.delete(`${GUIDE_PAGE_URL}/${id}`),
}