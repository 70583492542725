import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../../../shared/toasters/toasters";
import ImageViewer from "react-simple-image-viewer";
import {ConfirmDelete} from "../../../../shared/components/ConfirmDelete";
import {Card, Col, Form, Row} from "react-bootstrap";
import {AdminGuideFirmGalleryService} from "./service";
import SortableList, {SortableItem} from "react-easy-sort";
import {arrayMoveImmutable} from "array-move";

export const Gallery = ({rootState,companyId}: any) => {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [show, setShow] = useState(false);
    const [state, setState] = useState<any[]>();
    const [photoAlbums, setPhotoAlbums] = useState<File[]>([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [selectedImage, setSelectedImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
    const openImageViewer = useCallback((index: any) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const handleShow = () => setShow(true);
    const updatePosition = async (newIds: number[]) => {
        const newArray = newIds?.map((item: any) => item?.id);
        await AdminGuideFirmGalleryService.updatePosition({ids: newArray});
    };
    const onSortEnd = (oldIndex: number, newIndex: number) => {
        updatePosition(arrayMoveImmutable(state as any, oldIndex, newIndex));
        setState((array: any) => arrayMoveImmutable(array, oldIndex, newIndex));
    };
    const deleteHandler = () => {
        selectedPrompt &&
        AdminGuideFirmGalleryService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`gallery.successfully_deleted`));
                    setTriggerUpdate((prev: boolean) => !prev);
                    setSelectedImage(0);
                }
            })
            .catch((error) => ErrorToast(error));
    };

    const submitHandler = ( id?: number )=>{
        const albumData = new FormData();
        if (photoAlbums?.length) {
            for (let image of photoAlbums) {
                albumData.append("image[]", image as File);
            }
            albumData.append('body', JSON.stringify({firm: id}))
            AdminGuideFirmGalleryService.create(albumData, rootState?.language)
                .then(()=>{
                    SuccessToast(t(`gallery.successfully_updated`));
                    setTriggerUpdate(prev=> !prev)
                })
                .catch((error) => ErrorToast(error));
        }

    }
    useEffect(() => {
        if (!!Number(id)) {
            AdminGuideFirmGalleryService.getSingle(id as unknown as number)
                .then((response) => {
                    const {data} = response;
                    const mapped = data?.data?.map((doc: any) => {
                        return {
                            id: doc.image?.id,
                            path: doc.image.path,
                        };
                    });
                    setState(mapped);
                })
                .catch((error) => ErrorToast(error));
        }
    }, [id, triggerUpdate]);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                submitHandler(companyId);
            }}
        >
        <Card>
            <Card.Body>
                <Row>
                    <Col md={12} className="my-3">
                        <label htmlFor="imageFile">{t("album.photo_gallery")}</label>
                        <input
                            onChange={(e) => setPhotoAlbums(e.target.files as unknown as File[])}
                            multiple={true}
                            className="form-control"
                            type="file"
                            accept="image/*"
                            id="imageFile"
                        />
                    </Col>


                {!!companyId &&
                    <Col md={12}>
                        <SortableList
                            onSortEnd={onSortEnd}
                            className="list"
                            draggedItemClassName="dragged"
                        >
                            {state?.map((item, index) => (
                                <div className={'d-inline-block position-relative'} key={item?.id}>
                                    <SortableItem key={item?.id}>
                                        <img
                                            src={item?.path}
                                            className="object-fit-cover cursor-move"
                                            onClick={() => setSelectedImage(item?.id)}
                                            onDoubleClick={() => openImageViewer(index)}
                                            key={item?.id}
                                            width={250}
                                            height={250}
                                            style={{margin: "2px"}}
                                            alt=""
                                        />
                                    </SortableItem>
                                    <i onClick={() => {
                                        handleShow();
                                        setSelectedPrompt(item);
                                    }}
                                       className={'ri-delete-bin-fill bg-danger text-white delete-icon-position'}>
                                    </i>
                                </div>
                            ))}
                        </SortableList>
                        {isViewerOpen && (
                            <ImageViewer
                                src={state!?.map((img: any) => img?.path as unknown as string)}
                                backgroundStyle={{zIndex: 10000}}
                                currentIndex={currentImage}
                                disableScroll={false}
                                closeOnClickOutside={true}
                                onClose={closeImageViewer}
                            />
                        )}
                    </Col>
                }
                    <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                        <div>
                            <button
                                className="btn btn-info"
                                type={"button"}
                                onClick={() => {
                                    navigate(-1)
                                    setState([]);
                                }}
                            >
                                {t("global.cancel")}
                            </button>
                            <button className="btn btn-primary ms-3" type="submit">
                                {companyId ? `  ${t("global.update")}` : `${t("global.create")}`}
                            </button>
                        </div>
                    </div>
                </Row>

                <ConfirmDelete
                    show={show}
                    setShow={setShow}
                    itemName={selectedPrompt?.title}
                    deleteHandler={deleteHandler}
                    product={selectedPrompt}
                    selectedProduct={selectedPrompt}
                />
            </Card.Body>
        </Card>
        </Form>
    );
};
