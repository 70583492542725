import React, {SetStateAction, useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {AdminGuidePageService} from "../service";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {InitEditor} from "../../tests/components/CreateForm";
import {IGuidePage} from "../interface";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {IEnum} from "../../../store/slices/enum.slice";
import Select from "react-select";

interface IProps {
    state: IGuidePage | null,
    setState: React.Dispatch<SetStateAction<IGuidePage | null>>
}

export const CreateForm = ({state, setState}: IProps) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const {guideLanguage, guideMenuPosition} = useSelector((state: RootState) => state.enum) as IEnum;

    const mappedLanguages = guideLanguage?.map((type: string) => ({
        label: t(`languages.${type?.toLowerCase()}`),
        value: type,
    }));

    const mappedMenuPositions = guideMenuPosition?.map((type: string) => ({
        label: t(`enums.${type}`),
        value: type,
    }));
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value}) as IGuidePage);
    };
    const selectHandler = (e: any, eventKey: string, isMultiple = false) => {
        setState((prev: any) => ({...prev, [eventKey]: isMultiple ? e.map((item: {value: string})=> item.value) : e.value}));
    };

    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, [e.target.name]: e.target.checked }) as IGuidePage);
    };
    const handleText = (text: string) => {
        setState((prev) => ({...prev, text}) as IGuidePage);
    };
    useEffect(() => {
        if (!!Number(id)) {
            AdminGuidePageService.getSingle(id as unknown as number)
                .then((response) => {
                    const {data} = response;
                    setState(data);
                    window.scrollTo(0, 0);
                    InitEditor(data?.text, handleText);
                })
                .catch((error) => ErrorToast(error));
        } else {
            InitEditor(undefined, handleText);
        }
    }, [id]);
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={6}>
                        <label htmlFor="title">
                            {t("global.title")}
                        </label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            required
                            onChange={(e) => changeHandler(e as any)}
                            value={state?.id && state?.title}
                        />
                    </Col>
                    <Col md={6}>
                        <label htmlFor="url">
                            {t("global.url")}
                        </label>
                        <input
                            type="text"
                            name="url"
                            className="form-control"
                            required
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e)}
                            value={state?.id && state?.url}
                        />
                    </Col>
                    <Col md={5} className="my-3">
                        <label htmlFor="language">
                            {t("global.language")}
                        </label>
                        <Select
                            className="react-select-field"
                            onChange={(e) => selectHandler(e, 'language')}
                            placeholder="Search language"
                            options={mappedLanguages}
                            value={mappedLanguages?.find(
                                (data: { value: string }) => data.value === state?.language
                            )}
                        />
                    </Col>
                    <Col md={5} className="my-3">
                        <label htmlFor="url">
                            {t("global.position")}
                        </label>
                        <Select
                            className="react-select-field"
                            onChange={(e) => selectHandler(e, 'menuPosition', true)}
                            options={mappedMenuPositions}
                            required
                            isMulti
                            isClearable
                            value={mappedMenuPositions?.filter(
                                (data: { value: string }) => state?.menuPosition?.some(position=> position === data.value)
                            )}
                        />
                    </Col>
                    <Col md={2} className={'my-3'}>
                        <div className="form-check form-switch">
                            <label className="d-block text-start" htmlFor="flexSwitchCheckDefault">
                                {t("menu.is_target_blank")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-3 mt-2"
                                    type="checkbox"
                                    name="isTargetBlank"
                                    checked={state?.isTargetBlank}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={12} className="mb-2">
                        <label htmlFor="text">
                            {t("global.text")}
                        </label>
                        <textarea className="editor" onChange={console.log}></textarea>
                    </Col>
                </Row>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                    <div>
                        <button
                            className="btn btn-info"
                            onClick={() => {
                                navigate("/admin/guide-page");
                            }}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary ms-3" type="submit">
                            {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
