import React, {useEffect, useState} from "react";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {useNavigate} from "react-router-dom";
import {AdminGuideFirmService} from "./service";
import {Pagination} from "../../shared/paginations/Paginations";
import Select from "react-select";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {IEnum} from "../../store/slices/enum.slice";

export const GuideFirm = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
    const [show, setShow] = useState(false);
    const { guideLanguage } = useSelector((state: RootState) => state.enum) as IEnum;
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
        term: "",
    });
    const [list, setList] = useState<any>();
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const handleShow = () => setShow(true);
    const deleteHandler = (selectedPrompt: any) => {
        selectedPrompt?.id &&
        AdminGuideFirmService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    setList((prev: any) =>
                        prev.filter((item: any) => item.id !== selectedPrompt.id)
                    );
                    SuccessToast(t(`guide_firm.successfully_deleted`));
                    setTriggerUpdate((prev: boolean) => !prev);
                }
            })
            .catch((error) => ErrorToast(error));
    };
    const editHandler = (id: number): void => {
        navigate(`/admin/guide-firm/update/${id}`);
    };
    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminGuideFirmService.updatePosition({ids: newListUpdated});
    };

    const createNew = (): void => {
        navigate("/admin/guide-firm/create");
    };
    const mappedLanguages = guideLanguage?.map((type: string) => ({
        label: t(`languages.${type?.toLowerCase()}`),
        value: type,
    }));
    useEffect(() => {
        AdminGuideFirmService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                setList(
                    data?.items.map((item: any) => ({
                        ...item,
                        positionMenu: item?.positionMenu?.location,
                    }))
                );
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [triggerUpdate, pagination]);
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body d-flex align-items-center ">
                        <div className="d-flex align-items-center gap-3">
                            <div className="search-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="searchMemberList"
                                    onChange={(e: any) =>
                                        setPagination((prev: any) => ({
                                            ...prev,
                                            page: 1,
                                            term: e.target.value,
                                        }))
                                    }
                                    placeholder={t("global.search")}
                                />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                            <div className="d-flex align-items-center">
                                <h6 className="my-auto me-2 p-0 ">Language:</h6>
                                <Select
                                    className="react-select-field"
                                    onChange={(e: any) => {
                                        setPagination((prev) => ({...prev, language: e?.value}))
                                    }}
                                    placeholder="Search language"
                                    isClearable
                                    options={mappedLanguages}
                                />
                            </div>
                        </div>
                        <button className="btn btn-primary ms-auto" onClick={createNew}>
                            {t("global.add")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <DraggableBootstrapTable
                    preview={true}
                    list={list!}
                    setList={setList}
                    headers={["id", "title", "language", "active"]}
                    title={'guide_firm'}
                    onEdit={editHandler}
                    selectedPrompt={selectedPrompt}
                    setSelectedPrompt={setSelectedPrompt}
                    handleShow={handleShow}
                    show={show}
                    setShow={setShow}
                    deleteHandler={deleteHandler}
                    updatePosition={updatePosition}
                />
            </div>

            <div className="d-flex justify-content-end ">
                {totalPages <= 1 ? (
                    ""
                ) : (
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                )}
            </div>
        </>
    );
};
