import React, {useEffect, useState} from 'react';
import {CreateForm} from "../tabs/form/CreateForm";
import {useTranslation} from "react-i18next";
import {Gallery} from "../tabs/gallery/Gallery";
import EmployeesTab from "../tabs/employees/EmployeesTab";
import {AdminGuideFirmService} from "../service";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {useParams} from "react-router-dom";
import Select from "react-select";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {IEnum} from "../../../store/slices/enum.slice";

const tabs = ['company', 'gallery', 'employees']

interface IProps {
    isUpdate?: boolean;
}
const GuideFirmTabs = ({isUpdate}: IProps) => {
    const {id} = useParams();
    const [state, setState] = useState<any>();
    const {t} = useTranslation();
    const { guideLanguage } = useSelector((state: RootState) => state.enum) as IEnum;
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const getTabComponent = (currentTab: string) => {
        const tabMapper = {
            'company': <CreateForm state={state} setState={setState}/>,
            'gallery': <Gallery companyId={state?.id} rootState={state}  setState={setState}/>,
            'employees': <EmployeesTab companyId={state?.id} rootState={state} />
        }
        return tabMapper[currentTab as keyof typeof tabMapper] || `No component for tab ${currentTab}`
    }
    const mappedLanguages = guideLanguage?.map((type: string) => ({
        label: t(`languages.${type?.toLowerCase()}`),
        value: type,
    }));
    const selectHandler = (e: any) => {
        setState((prev: any) => ({...prev, language: e.value, categories: []}));
    };
    useEffect(() => {
        if (!!Number(id)) {
            AdminGuideFirmService.getSingle(+id!)
                .then((response) => {
                    const {data} = response;
                    setState({
                        ...data,
                        categories: data?.categories?.map((site: any) => ({
                            label: site?.title,
                            value: site?.id,
                        })),
                    });
                    window.scrollTo(0, 0);
                })
                .catch((error) => ErrorToast(error));
        }
    }, [id]);

    return (
        <>
           <div className="my-4 d-flex align-items-center justify-content-center">
               <h5 className="text-center mb-0 me-3">
                   {state?.id ? t('guide_firm.updating') : t('guide_firm.creating')}: {state?.title || '-'}
               </h5>
               {!isUpdate && (
                   <Select
                       className="react-select-field"
                       onChange={selectHandler}
                       options={mappedLanguages}
                       value={mappedLanguages?.find(
                           (data: any) => data.value === state?.language
                       )}
                   />
               )}

           </div>
            <ul className="nav nav-tabs nav-tabs-custom rounded card-header-tabs"
                id="myTab"
                role="tablist"
            >
                {tabs?.map(tab => {
                    const isDisabled = tab !== 'company' && !state?.id
                    return (
                        <li className="nav-item" role="presentation" key={tab}>
                            <button
                                onClick={() => setActiveTab(tab)}
                                className={`nav-link ${activeTab === tab ? 'active' : ''} ${isDisabled ? 'opacity-25' : ''}`}
                                id={`${tab}-tab`}
                                data-bs-toggle="tab"
                                data-bs-target={`#${tab}`}
                                type="button"
                                role="tab"
                                disabled={isDisabled}
                                aria-controls={tab}
                                aria-selected="true"
                            >
                                {t(`table_headers.${tab}`)}
                            </button>
                        </li>
                    )
                })}
            </ul>
            <div className="tab-content mt-1" id="myTabContent">

                {tabs?.map(tab => {
                    return (
                        <div
                            className={`tab-pane fade ${tab === activeTab ? 'show active' : ''}`} key={tab}
                            id={tab}
                            role="tabpanel"
                            aria-labelledby={`${tab}-tab`}
                        >

                            {getTabComponent(tab)}
                        </div>
                    )
                })}
            </div>
        </>
    );
};

export default GuideFirmTabs;