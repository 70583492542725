import {GUIDE_FIRM_CATEGORY_URL} from "../../helpers/api.routes";
import {requests} from "../../helpers/api.services";

export const AdminGuideFirmCategoryService = {
  getAll: (params: any) => requests.get(`${GUIDE_FIRM_CATEGORY_URL}`, params),
  create: (params: any) =>
    requests.post(`${GUIDE_FIRM_CATEGORY_URL}`, params, true),
  updatePosition: (params: any) =>
    requests.patch(`${GUIDE_FIRM_CATEGORY_URL}`, params),
  getSingle: (id: number) => requests.get(`${GUIDE_FIRM_CATEGORY_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${GUIDE_FIRM_CATEGORY_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${GUIDE_FIRM_CATEGORY_URL}/${id}`),
};
