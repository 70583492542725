import React, {useEffect, useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {ErrorToast, SuccessToast} from "../../../../shared/toasters/toasters";
import {AdminGuideFirmService} from "../../service";
import {useNavigate, useParams} from "react-router-dom";
// import {N1ED} from "@edsdk/n1ed-react";
// import {Editor} from "tinymce";
import {PICTURE_URL} from "../../../../helpers/api.routes";
import {AdminGuideFirmCategoryService} from "../../../guideFirmCategory/service";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
export const CreateForm = ({ state, setState }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [firmCategories, setFirmCategories] = useState<any[]>([]);
  const [image, setImage] = useState<File>();
  const website = localStorage.getItem("website");
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const mappedDataWebsite = firmCategories?.map((site: any) => ({
    label: site?.title,
    value: site?.id,
  }));

  const copyHandlerMulti = (selectedSingle: any) => {
    setState((prev: any) => ({
      ...prev,
      categories: selectedSingle,
    }));
  };
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  const submitHandler = () => {
    const sanitizedState = {
      ...state,
      email: state?.email || "",
      firmCategories: state?.categories?.map((item: any) => item?.value),
    };

    const formData = new FormData();
    formData.append("body", JSON.stringify(sanitizedState));
    image && formData.append("image", image);

    const correctApiCall = state?.id
        ? AdminGuideFirmService.update
        : AdminGuideFirmService.create;
    //@ts-ignore
    correctApiCall(formData, !!state?.id ? state?.id : state?.language, state?.language)
        .then((response) => {
          if (!state?.id) {
            navigate(`/admin/guide-firm/update/${response?.data?.id}`);
          }
          SuccessToast(
              t(`guide_firm.successfully_${state?.id ? "updated" : "created"}`)
          );
        })
        .catch((error) => ErrorToast(error));
  };

  const isFleetGuide = website === "fleet_guide"

  useEffect(() => {
    !!state?.language?.length && AdminGuideFirmCategoryService.getAll({
      perPage: 0,
      page: 1,
      active: true,
      language: state?.language
    })
        .then((response) => {
          const {data} = response;
          setFirmCategories(data?.items);
        })
        .catch((error) => ErrorToast(error));
  }, [state?.language]);
  const handleText = (description: string) => {
    setState((prev: any) => ({ ...prev, description }));
  };
  const handleTextPortrait = (companyPortrait: string) => {
    setState((prev: any) => ({ ...prev, companyPortrait }));
  };

  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminGuideFirmService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          InitEditor(data?.description || '', handleText, ".description");
          InitEditor(
            data?.companyPortrait || '',
            handleTextPortrait,
            ".companyPortrait"
          );
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditor(undefined, handleText, ".description");
      InitEditor(undefined, handleTextPortrait, ".companyPortrait");
    }
  }, [id]);
  useEffect(() => {
    removeElementsByClass(
      "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
    );
  },[]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        submitHandler();
      }}
    >
      <Card>
        <Card.Body>
          <Row>
            <Col md={4}>
              <label htmlFor="title" className="required-field-news">
                {t("global.company_name")}
              </label>
              <input
                type="text"
                name="title"
                className="form-control"
                required
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.title}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="firm_categories" className="required-field">
                {t("table_headers.firm_categories")}
              </label>
              <Select
                id="firm_categories"
                className="react-select-field"
                onChange={copyHandlerMulti}
                isMulti={true}
                value={state?.categories}
                options={mappedDataWebsite}
              />
            </Col>
            <Col md={2}>
              <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="form-check form-switch">
                <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="active"
                    checked={state?.active}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                />
              </div>
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="street" className="required-field">
                {t("global.street")}
              </label>
              <input
                type="text"
                name="street"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.street}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="plz" className="required-field">
                {t("global.plz")}
              </label>
              <input
                type="text"
                name="plz"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.plz}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="location" className="required-field">
                {t("global.location")}
              </label>
              <input
                type="text"
                name="location"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.location}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="latitude">
                {t("global.latitude")}
              </label>
              <input
                  type="text"
                  name="latitude"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.latitude}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="longitude">
                {t("global.longitude")}
              </label>
              <input
                  type="text"
                  name="longitude"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.longitude}
              />
            </Col>
            <Col md={4}></Col>
            <Col md={4} className="mt-1">
              <label htmlFor="phone" className="required-field">
                {t("global.phone")}
              </label>
              <input
                type="text"
                name="phone"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.phone}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="email">
                {t("global.email")}
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id ? state?.email || "" : ""}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="fax">
                {t("global.fax")}
              </label>
              <input
                  type="text"
                  name="fax"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id ? state?.fax || "" : ""}
              />
            </Col>
            <Col md={6} className="mt-1">
              <label htmlFor="titleUrl" className="required-field">
                {t("global.titleUrl")}
              </label>
              <input
                  type="text"
                  name="titleUrl"
                  className="form-control"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e)}
                  value={state?.id && state?.titleUrl}
              />
            </Col>
            <Col md={6} className="mt-1">
              <label htmlFor="titleUrl2" className="required-field">
                {t("global.titleUrl2")}
              </label>
              <input
                  type="text"
                  name="titleUrl2"
                  className="form-control"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e)}
                  value={state?.id && state?.titleUrl2}
              />
            </Col>
            <Col md={6} className="mt-1">
              <label htmlFor="url" className="required-field">
                {t("global.url")}
              </label>
              <input
                type="text"
                name="url"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.url}
              />
            </Col>
            <Col md={6} className="mt-1">
              <label htmlFor="url2" className="required-field">
                {t("global.url2")}
              </label>
              <input
                type="text"
                name="url2"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.url2}
              />
            </Col>
            <Col md={12} className="my-3">
              <div>
                <label className="d-block text-start" htmlFor="content">
                  {isFleetGuide ? t("global.range_and_services") : t("global.description")}
                </label>
                <textarea
                  className="editor description"
                  onChange={console.log}
                ></textarea>

              </div>
            </Col>
            <Col md={12} className="my-1">
              <div>
                <label className="d-block text-start" htmlFor="content">
                  {t("global.companyPortrait")}
                </label>
                <textarea
                  className="editor companyPortrait"
                  onChange={console.log}
                ></textarea>
              </div>
            </Col>
            <Col md={12} className="mt-2">
              <div>
                <label htmlFor="imageFile"> {t("download.image")}</label>
                <input
                  onChange={fileHandler}
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="imageFile"
                />
              </div>
              {state?.image && (
                <img
                  className="mt-3 rounded show-img-form"
                  alt="200x200"
                  src={PICTURE_URL + state?.image.path}
                  data-holder-rendered="true"
                />
              )}
            </Col>
            <div className="col-12 my-2 d-flex justify-content-end text-align-center">
              <div>
                <button
                  className="btn btn-info"
                  type={"button"}
                  onClick={() => {
                    navigate(-1);
                    setState({} as any);
                  }}
                >
                  {t("global.cancel")}
                </button>
                <button className="btn btn-primary ms-3" type="submit">
                  {state?.id
                    ? `  ${t("global.update")}`
                    : `${t("global.create")}`}
                </button>
              </div>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};
export function removeElementsByClass(className: string) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements && elements[0]?.parentNode?.removeChild(elements[0]);
  }
}

export function InitEditor(
  value: string = "",
  cb: (value: string) => void,
  className: string
) {
  (window as any).tinymce.init({
    selector: className,
    apiKey: "a5kCyOAV",
    width: "100%",
    height: 500,
    sidebar: undefined,
    menubar: "file edit view insert format tools table tc help",
    plugins:
      "image link file-manager print code preview importcss searchreplace autolink autosave save directionality  visualblocks visualchars fullscreen image link media  template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists  wordcount imagetools textpattern noneditable help   charmap  quickbars emoticons  ",
    statusbar: true,
    toolbar:
      "Upload undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | forecolor backcolor  removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media  template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment code",
    extended_valid_elements: "i[class]",
    init_instance_callback: (editor: any) => {
      editor.on("input", (e: any) => {
        cb(e.target.innerHTML);
      });
      editor.on("focus", (e: any) => {
        removeElementsByClass(
          "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
        );
      });
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            cb(editor.getContent());
          }
        }
      });
      const editorElement = editor.getContainer();
      observer.observe(editorElement, { childList: true, subtree: true });
    },
    setup: (editor: any) => {
      editor.on("init", () => {
        editor.setContent(value);
      });
    },
  });
}
