import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Card, Col, Form, Row} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {InitEditorMoreFields} from "../model/components/CreateForm";
import {AdminGuideFirmSettingsService} from "./service";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {IEnum} from "../../store/slices/enum.slice";
import {PICTURE_URL} from "../../helpers/api.routes";

interface IGuideFirmSettings {
    title?: string,
    url?: string,
    coverTitle?: string,
    subTitle?: string,
    text?: string,
    language?: string,
    image?: {
        name: string,
        path: string
    }
}

const GuideFirmSettings = () => {
    const {t} = useTranslation()
    const [state, setState] = useState<IGuideFirmSettings>({});
    const {guideLanguage} = useSelector((state: RootState) => state.enum) as IEnum;
    const [image, setImage] = useState<File | undefined>(undefined);
    const [activeTab, setActiveTab] = useState<string>('');
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prevState) => ({...prevState, [name]: value}) as IGuideFirmSettings)
    }
    const handleText = (text: string) => {
        setState((prevState) => ({...prevState, text}));
    };

    const handleCoverTitle = (coverTitle: string) => {
        setState((prevState) => ({...prevState, coverTitle}));
    };


    const submitHandler = () => {
        const formData = new FormData();
        formData.append('body', JSON.stringify(state))
        image && formData.append("image", image);
        AdminGuideFirmSettingsService.create(formData, activeTab)
            .then((response) => {
                SuccessToast(t(`guide_firm.successfully_updated_settings`));
                setState(response.data)
                setImage(undefined);
            }).catch((error) => ErrorToast(error))
    }
    const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setImage(e?.target?.files?.[0]);
    };
    useEffect(() => {
        !!activeTab?.length && AdminGuideFirmSettingsService.get(activeTab)
            .then((response) => {
                const {data} = response;
                setState(data);
                InitEditorMoreFields(data?.text ?? "", handleText, ".text");
                InitEditorMoreFields(data?.coverTitle ?? "", handleCoverTitle, ".coverTitle");
            })
            .catch((error) => ErrorToast(error));
    }, [activeTab]);

    useEffect(() => {
        if (guideLanguage?.length) {
            setActiveTab(guideLanguage?.[0])
        }
    }, [guideLanguage])
    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
        }} key={activeTab}>
            <ul className="nav nav-tabs nav-tabs-custom rounded card-header-tabs"
                id="myTab"
                role="tablist"
            >
                {guideLanguage?.map(tab => {
                    return (
                        <li className="nav-item" role="presentation" key={tab}>
                            <button
                                onClick={() => setActiveTab(tab)}
                                className={`nav-link ${activeTab === tab ? 'active' : ''}`}
                                id={`${tab}-tab`}
                                data-bs-toggle="tab"
                                data-bs-target={`#${tab}`}
                                type="button"
                                role="tab"
                                aria-controls={tab}
                                aria-selected="true"
                            >
                                {t(`languages.${tab?.toLowerCase()}`)}
                            </button>
                        </li>
                    )
                })}
            </ul>
            <div className="tab-content mt-1" id="myTabContent">
                {guideLanguage?.map(tab => {
                    return (
                        <div
                            className={`tab-pane fade ${tab === activeTab ? 'show active' : ''}`} key={tab}
                            id={tab}
                            role="tabpanel"
                            aria-labelledby={`${tab}-tab`}
                        >
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            <label>{t("global.title")}</label>
                                            <input
                                                name="title"
                                                type="text"
                                                className="form-control"
                                                onChange={changeHandler}
                                                value={state?.title ?? ''}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <label>{t("global.url")}</label>
                                            <input
                                                name="url"
                                                type="text"
                                                className="form-control"
                                                onChange={changeHandler}
                                                value={state?.url ?? ''}
                                            />
                                        </Col>
                                        <Col md={12} className={'mt-3'} id="custom-editor">
                                            <label htmlFor="title">
                                                {t("global.coverTitle")}
                                            </label>
                                            <textarea className="editor coverTitle"/>
                                        </Col>
                                        <Col md={12} className="my-3" id="custom-editor">
                                            <label htmlFor="title">
                                                {t("global.text")}
                                            </label>
                                            <textarea className="editor text"/>
                                        </Col>
                                        <Col md={12}>
                                            <div>
                                                <label htmlFor="imageFile"> {t("download.image")}</label>
                                                <input
                                                    onChange={fileHandler}
                                                    className="form-control"
                                                    type="file"
                                                    accept="image/*"
                                                    id="imageFile"
                                                />
                                            </div>
                                            {state?.image && (
                                                <img
                                                    className="mt-3 rounded show-img-form"
                                                    alt="200x200"
                                                    src={PICTURE_URL + state?.image.path}
                                                    data-holder-rendered="true"
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <div className="col-12 mt-5 d-flex justify-content-end">
                                        <button className="btn btn-primary ms-3" type={'submit'}>
                                            {t("global.save")}
                                        </button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    )
                })}
            </div>
        </Form>
    );
};

export default GuideFirmSettings;