import React, {useEffect, useState} from 'react';
import {CreateForm} from "../tabs/form/CreateForm";
import {useTranslation} from "react-i18next";
import {Gallery} from "../tabs/gallery/Gallery";
import EmployeesTab from "../tabs/employees/EmployeesTab";
import {AdminGreenPagesService} from "../service";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {useParams} from "react-router-dom";

const tabs = ['company', 'gallery', 'employees']

const GreenPagesTabs = () => {
    const {id} = useParams();
    const [state, setState] = useState<any>();
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const getTabComponent = (currentTab: string) => {
        const tabMapper = {
            'company': <CreateForm state={state} setState={setState}/>,
            'gallery': <Gallery companyId={state?.id} setState={setState}/>,
            'employees': <EmployeesTab companyId={state?.id}/>
        }
        return tabMapper[currentTab as keyof typeof tabMapper] || `No component for tab ${currentTab}`
    }
    useEffect(() => {
        if (!!Number(id)) {
            AdminGreenPagesService.getSingle(+id!)
                .then((response) => {
                    const {data} = response;
                    setState({
                        ...data,
                        keywords: data?.keywords?.split(',')?.map((keyword: string) => ({
                            value: keyword,
                            label: keyword,
                        })),
                        categories: data?.categories?.map((site: any) => ({
                            label: site?.title,
                            value: site?.id,
                        })),
                    });
                    window.scrollTo(0, 0);
                })
                .catch((error) => ErrorToast(error));
        }
    }, [id]);
    return (
        <>
            <h5 className="text-center my-4">
                {state?.id ? t('green_pages.updating') : t('green_pages.creating')}: {state?.title || '-'}
            </h5>
            <ul className="nav nav-tabs nav-tabs-custom rounded card-header-tabs"
                id="myTab"
                role="tablist"
            >
                {tabs?.map(tab => {
                    const isDisabled = tab !== 'company' && !state?.id
                    return (
                        <li className="nav-item" role="presentation" key={tab}>
                            <button
                                onClick={() => setActiveTab(tab)}
                                className={`nav-link ${activeTab === tab ? 'active' : ''} ${isDisabled ? 'opacity-25' : ''}`}
                                id={`${tab}-tab`}
                                data-bs-toggle="tab"
                                data-bs-target={`#${tab}`}
                                type="button"
                                role="tab"
                                disabled={isDisabled}
                                aria-controls={tab}
                                aria-selected="true"
                            >
                                {t(`table_headers.${tab}`)}
                            </button>
                        </li>
                    )
                })}
            </ul>
            <div className="tab-content mt-1" id="myTabContent">

                {tabs?.map(tab => {
                    return (
                        <div
                            className={`tab-pane fade ${tab === activeTab ? 'show active' : ''}`} key={tab}
                            id={tab}
                            role="tabpanel"
                            aria-labelledby={`${tab}-tab`}
                        >

                            {getTabComponent(tab)}
                        </div>
                    )
                })}
            </div>
        </>
    );
};

export default GreenPagesTabs;