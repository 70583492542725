import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {AdminGuidePageService} from "../service";
import {CreateForm} from "./CreateForm";
import {IGuidePage} from "../interface";

const CreateGuidePage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [state, setState] = useState<IGuidePage | null>(null);

    const submitHandler = () => {
        state && AdminGuidePageService.create(state)
            .then(() => {
                SuccessToast(t(`guide_page.successfully_created`));
                navigate(-1);
                console.log(state, 'state')
                setState(null);
            })
            .catch(ErrorToast);
    };
    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm state={state} setState={setState}/>
            </Form>
        </div>
    );
};

export default CreateGuidePage;