import {GUIDE_FIRM_URL} from "../../helpers/api.routes";
import {requests} from "../../helpers/api.services";

export const AdminGuideFirmService = {
  getAll: (params: any) => requests.get(`${GUIDE_FIRM_URL}`, params),
  create: (params: any, language?: string) => requests.post(`${GUIDE_FIRM_URL}?language=${language}`, params, true),
  getSingle: (id: number) => requests.get(`${GUIDE_FIRM_URL}/${id}`),
  update: (params: any, id: number, language?: string) =>
    requests.post(`${GUIDE_FIRM_URL}/${id}`, params, true),
  updatePosition: (params: any) => requests.patch(`${GUIDE_FIRM_URL}`, params),
  delete: (id: number) => requests.delete(`${GUIDE_FIRM_URL}/${id}`),
};