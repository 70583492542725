import React, {useState} from 'react';
import LineChart from "../../../shared/components/LineChart";
import DatePicker from "react-datepicker";
import {useTranslation} from "react-i18next";
import {Accordion, Form, Spinner} from "react-bootstrap";
import {AdminNewsLetterService} from "../service";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {formatDate} from "../../../shared/functions/formatDate";
import axios, {AxiosRequestConfig} from "axios";

export interface IStatisticGraph {
    [key: string]: {
        averageClickRate: number;
        averageOpenRate: number;
    }
}

interface INews {
    id: number;
    title: string;
    clicks: number
}

interface INewsletter {
    id: number;
    subject: string;
    createdAt: string;
    totalMailsSent?: number;
    totalSubscriber?: number;
    totalPreviews?: number;
    openRate?: number;
    clickRate?: number;
    totalClicks?: number;
    news: INews[]
}

interface IStatistics {
    graph?: IStatisticGraph
    newsletters: INewsletter[]
}

const NewsletterStatistic = () => {
    const {t} = useTranslation();
    const [state, setState] = useState({
        fromDate: undefined,
        toDate: undefined,

    });
    const [isLoading, setIsLoading] = useState(false);
    const [statistics, setStatistics] = useState<IStatistics | null>(null)

    const downloadXLSFile = async () => {
        const website = localStorage.getItem("website");
        const appVersion = localStorage.getItem("appVersion");
        const headers = {
            "Content-Type": "blob",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "app-version": appVersion,
            "Aw-Web-Site": website ?? "aw",
        };
        const config: AxiosRequestConfig = {
            method: "PATCH",
            url: `${
                process.env.NODE_ENV === "development"
                    ? process.env.REACT_APP_DEV_MODE
                    : process.env.REACT_APP_PRO_MODE
            }admin/newsletters/statistic/export?fromDate=${formatDate(String(state?.fromDate), 'DD-MM-YYYY')}&toDate=${formatDate(String(state?.toDate), 'DD-MM-YYYY')}`,
            responseType: "arraybuffer",
            headers,
        };
        try {
            const response = await axios(config);
            const url = URL.createObjectURL(new Blob([(response as any)?.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            ErrorToast(e)
        }
    };

    function handleDate(date: Date, key: 'fromDate' | 'toDate') {
        const value = new Date(date);
        value.setHours(12);
        setState((prev: any) => ({...prev, [key]: value}));
    }

    const generateStatistics = async () => {
        if (!!state?.fromDate && !!state?.toDate) {
            setIsLoading(true);
            await AdminNewsLetterService.generateStatistic(state).then(response => {
                setStatistics(response?.data)
            }).catch(err => ErrorToast(err)).finally(() => setIsLoading(false));
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    generateStatistics()
                }}>
                    <div className="row align-items-end pb-3">
                        <div className="col-md-4">
                            <label htmlFor="date" className="required-field">
                                {t("global.startDate")}
                            </label>
                            <div className="datepicker-container">
                                <DatePicker
                                    className="date-picker custom_zindex"
                                    dateFormat="dd/MM/yyyy"
                                    required
                                    selected={state?.fromDate}
                                    value={state?.fromDate}
                                    onChange={(date) => {
                                        handleDate(date as Date || new Date(), 'fromDate')
                                    }
                                    }
                                    isClearable
                                    placeholderText="Choose date..."
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="date" className="required-field">
                                {t("global.endDate")}
                            </label>
                            <div className="datepicker-container">
                                <DatePicker
                                    className="date-picker custom_zindex"
                                    dateFormat="dd/MM/yyyy"
                                    required
                                    selected={state?.toDate}
                                    value={state?.toDate}
                                    onChange={(date) => {
                                        handleDate(date as Date || new Date(), 'toDate')
                                    }
                                    }
                                    isClearable
                                    placeholderText="Choose date..."
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <button className={'btn btn-primary'} type={"submit"}
                                    disabled={state?.fromDate === undefined || state?.toDate === undefined || isLoading}>
                                {isLoading ? <Spinner size={'sm'}/> : 'Generate'}
                            </button>
                            {statistics !== null &&
                                <button className={'btn btn-success ms-3'}
                                        type={'button'}
                                        onClick={() => downloadXLSFile()}>
                                    {t('global.export')}
                                </button>
                            }
                        </div>
                    </div>
                </Form>
                {statistics !== null && <>
                    <LineChart chartData={statistics?.graph as IStatisticGraph}/>
                    <div className="mt-5">
                        <div className={'d-flex align-items-center justify-content-between fw-bold text-muted'}>
                            <div style={{width: "5%", paddingLeft: '15px'}}>ID</div>
                            <div style={{width: "25%"}}>{t('newsletter_statistic.subject')}</div>
                            <div style={{width: "15%"}}>{t('global.created_at')}</div>
                            <div style={{width: "10%"}}>{t('newsletter_statistic.total_sent')}</div>
                            <div style={{width: "10%"}}>{t('newsletter_statistic.total_previews')}</div>
                            <div style={{width: "10%"}}>{t('newsletter_statistic.open_rate')}</div>
                            <div style={{width: "10%"}}>{t('newsletter_statistic.click_rate')}</div>
                            <div style={{width: "10%"}}>{t('newsletter_statistic.total_clicks')}</div>
                        </div>
                        <div>
                            {statistics?.newsletters?.map(newsletter => (
                                <Accordion key={newsletter?.id}>
                                    <Accordion.Item eventKey={String(newsletter?.id)}>
                                        <Accordion.Header>
                                            <div className={'d-flex align-items-center justify-content-between w-100'}>
                                                <div style={{width: "5%"}}>{newsletter?.id}</div>
                                                <div style={{width: "25%"}}>{newsletter?.subject}</div>
                                                <div style={{width: "15%"}}>{formatDate(newsletter?.createdAt)}</div>
                                                <div style={{width: "10%"}}
                                                     className={'text-center'}>{newsletter?.totalMailsSent}</div>
                                                <div style={{width: "10%"}}
                                                     className={'text-center'}>{newsletter?.totalPreviews}</div>
                                                <div style={{width: "10%"}}
                                                     className={'text-center'}>{newsletter?.openRate}</div>
                                                <div style={{width: "10%"}}
                                                     className={'text-center'}>{newsletter?.clickRate}</div>
                                                <div style={{width: "10%"}}
                                                     className={'text-center'}>{newsletter?.totalClicks}</div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {newsletter?.news?.length ? (
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Title</th>
                                                        <th>Clicks</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {newsletter?.news?.map(news => (
                                                        <tr key={news?.id}>
                                                            <td>{news?.id}</td>
                                                            <td>{news?.title}</td>
                                                            <td>{news?.clicks}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <p>{t('newsletter_statistic.no_news')}</p>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            ))}
                        </div>
                    </div>
                </>}
            </div>
        </div>
    );
};

export default NewsletterStatistic;